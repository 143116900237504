<template>
	<div class="gift-nft">
		<div class="container is-max-desktop">
			<div class="columns is-full-height is-centered is-gapless is-mobile is-desktop" :class="{ 'is-vcentered': !accountName }">
				<!-- <div v-if="!waxAccountName" class="column is-5-tablet is-5-desktop">
					<div class="login-required content">
						<h1>Hmm, looks like you're not logged in.</h1>
						<p>
							In order for us to gift one of your NFT's, we'll need you to login
							to your preferred provider using the button below...
						</p>
						<div id="login-div" class="login-button">
							<button @click="login()" ref="ualLogin" class="ual-button-gen">
								Login
							</button>
						</div>
					</div>
				</div> -->

				<div v-if="accountName" class="column is-10-tablet is-11-desktop">
					<div class="columns is-mobile is-multiline" :class="{ 'is-vcentered': showConfirmation }">
						<div class="column is-full-mobile is-half-tablet is-5-desktop is-hidden-mobile">
							<div class="gift-preview">
								<div class="header">
									<div class="columns is-gapless is-mobile">
										<div class="column">
											<img
												src="@/assets/img/logo.svg"
												width="78"
												height="28"
												alt="GiftNFT"
											/>
										</div>
										<div class="column">
											<button class="button is-primary is-rounded is-outlined is-uppercase has-text-weight-bold">
												Claim
											</button>
										</div>
									</div>
								</div>
								<div class="asset-preview">
									<img :src="selectedAssetImgUrl" />
									<div v-if="selectedAssetTemplateMint || selectedAssetName" class="asset-name">
										<div class="columns is-mobile is-gapless">
											<div v-if="selectedAssetTemplateMint && domain == DOMAIN.WAX" class="column is-narrow">
												<div class="num" v-text="'#' + selectedAssetTemplateMint"></div>
											</div>
											<div v-if="selectedAssetName" class="column">
												<div :class="{ 'name': domain == !DOMAIN.MEME, 'name-center': domain == DOMAIN.MEME }" v-text="selectedAssetName"></div>
											</div>
										</div>
									</div>
								</div>
								<div class="msg-box">
									<p v-text="message"></p>
								</div>
							</div>
							<div class="preview-disclaimer">
								Gift NFT Preview /<br />This is what the gifted user will see
							</div>
						</div>

						<div class="column is-full-mobile is-half-tablet is-7-desktop">
							<div v-if="errors.length" class="notification is-danger">
								<p v-for="error in errors" v-bind:key="error">{{ error }}</p>
							</div>
                             <!-- Confirmation Modal Starts-->
							<div class="confirm-send modal" :class="{ 'is-active' : showConfirmModal}">
								<div class="modal-background" @click="closeConfirmModal()"></div>
								<div class="modal-card">
									<header class="modal-card-head">
										<p class="modal-card-title has-text-weight-bold">
											Are you sure?
										</p>
										<button
											@click="closeConfirmModal()"
											class="delete"
											aria-label="close">
										</button>
									</header>
									<section class="modal-card-body">
										<div v-if="!isSendingGift">
											By clicking 'confirm', you acknowledge and agree to send
											the following NFT to <strong> {{ getToName() }}</strong>. This action is
											irreversable, so please make sure all
											the info is correct.
                                            <br/><br/>
                                            If gift isn't claimed within <strong>30 days</strong> we'll send it back to your wallet.
										</div>
									</section>
									<footer class="modal-card-foot">
										<button
											class="button is-primary"
											@click="createGifts()">
											Confirm
										</button>
										<button
											@click="closeConfirmModal()"
											class="button is-text is-outlined">
											Cancel
										</button>
									</footer>
								</div>
							</div>
                            <!-- Confirmation Modal Ends-->

                             <!-- Loading Modal Starts-->
							<div class="confirm-loading modal" :class="{ 'is-active' : showLoadingModal}">
								<div class="modal-background" @click="closeLoadingModal"></div>
								<div class="modal-card">
									<section class="modal-card-body">
										<div class="has-text-centered">
											<div class="loading"></div>
											<h1>We're working on sending your NFT.</h1>
											<p>Please confirm the transaction in your wallet.</p>
										</div>
									</section>
								</div>
							</div>
                            <!-- Loading Modal Ends-->

                            <!-- Confirmation DIV Starts-->
							<div v-if="showConfirmation" class="gift-form-confirmation content">
								<h2>Gift has been sent!</h2>
								<p>
									You have gifted the following NFT to
									<strong>{{ to ? to : `${toPhone}` }}</strong>. They should be getting {{ to ? "an" : "a" }}
									<strong>{{ to ? "email" : "text" }}</strong> shortly with
									instructions on how to 'claim' their new NFT.
								</p>
								<p class="has-text-weight-bold">
								<a target="_blank" :href="waxTxUrl">View the blockchain transaction here</a>
								</p>
                                <!-- <p class="has-text-weight-bold">
                                <a target="_blank" class="twitter-share-button" :href="`https://twitter.com/intent/tweet?text=I%20have%20just%20gifted%20this%20NFT!%20${selectedAssetImgUrl}%0A&url=https://gift.bountyblok.io/`">Tweet</a>
								</p> -->
                                <button
									@click="reload"
									class="create-new button is-medium is-rounded is-link">
									Send Another
								</button>
							</div>
                            <!-- Confirmation DIV Ends-->

							<div v-else class="gift-form content">
								<h2>Gift a NFT</h2>
								<p>
									Securely send any of your NFTs instantly to friends, family,
									colleagues, or anyone of your choosing! Simply fill in the
									form below, sit back, and we’ll handle the rest...
								</p>
								<article class="message is-warning" v-if="showMintNftMsg">
									<div class="message-header">
										<p class="m-0 p-0">Oops</p>
										<button class="delete" aria-label="delete"></button>
									</div>
									<div class="message-body">
										Doesn't look like you have any NFTs to gift. Fear not - you can mint your own, <a target="_blank" :href="getExplorerUrl()">right here.</a>
									</div>
								</article>
								<div v-else>
                               <div class="columns">
									<div class="column is-full-mobile is-half-tablet is-half-desktop">
										<div class="field">
											<label class="label">
												Collection
												<span data-tooltip="List of collections you own assets from">
													<i class="far fa-question-circle" ></i>
												</span>
											</label>
											<div class="control">
												<collection-dropdown :options="availableCollections" :domain="domain" :domainSettings="domainSettings" :activeOption="collectionName" @selected="collectionName = $event" @change="onCollectionChange($event)"/>
											</div>
										</div>
									</div>

									<div v-if="collectionName && (domain != DOMAIN.MEME || domain == DOMAIN.NEWDEX)" class="column is-full-mobile is-half-tablet is-half-desktop">
										<div class="field">
											<label class="label">
												Template
												<span data-tooltip="List of templates you own assets from">
													<i class="far fa-question-circle" ></i>
												</span>
											</label>
											<div class="control">
												<template-dropdown
													:options="availableTemplates"
													:activeOption="templateOptionName"
													@selected="templateOptionName = $event"
													@id="templateID = $event"
													@change="onTemplateChange()"
												/>
											</div>
										</div>
									</div>
								</div>

								<div :key="componentKey" v-if="availableAssets.length" class="nft-selector">
									<label class="label">Which NFT would you like to gift?</label>
									<vue-horizontal-list
									:items="availableAssets"
									:options="sliderOptions">
										<template v-slot:default="{ item }">
											<div @click="selectAsset(item)" class="item" :class="selectedAsset == item.assetID ? 'is-selected' : ''">
												<div class="img-container">
													<img :src="item.imgUrl" />
												</div>
												<div v-if="item.name" class="item-name">{{ item.name }}</div>
												<div class="item-num" v-if="domain == DOMAIN.WAX">#{{ item.templateMint }}</div>
											</div>
										</template>
									</vue-horizontal-list>
								</div>

                                <!-- Delivery type removed from v1 -->
								<div v-if="availableAssets.length && selectedAsset" class="send-method">
									<label class="label">How would you like to send this gift?</label>
									<div class="buttons has-addons">
										<button
											@click="changeSendType(deliveryType.EMAIL)"
											:class="deliveryby == deliveryType.EMAIL ? 'is-selected' : ''"
											class="button">
											<span class="icon is-small">
												<i class="far fa-envelope-open"></i>
											</span>
											<span>Email</span>
										</button>
										<!-- <button
											@click="changeSendType(deliveryType.TEXT)"
											:class="deliveryby == deliveryType.TEXT ? 'is-selected' : ''"
											class="button">
											<span class="icon is-small">
												<i class="far fa-sms"></i>
											</span>
											<span>Text</span>
										</button> -->
										<!-- <button
											@click="changeSendType(deliveryType.TWITTERDM)"
											:class="deliveryby == deliveryType.TWITTERDM ? 'is-selected' : ''"
											class="button" v-if="domain == DOMAIN.WAX || domain == DOMAIN.MEME || domain == DOMAIN.NEWDEX || domain == DOMAIN.HEDERA">
											<span class="icon is-small">
												<i class="fab fa-twitter"></i>
											</span>
											<span>Twitter</span>
										</button> -->
                                        <!-- <button
											@click="changeSendType(deliveryType.DISCORDDM)"
											:class="deliveryby == deliveryType.DISCORDDM ? 'is-selected' : ''"
											class="button" v-if="domain == DOMAIN.WAX">
											<span class="icon is-small">
												<i class="fab fa-discord"></i>
											</span>
											<span>Discord</span>
										</button> -->
									</div>
								</div>
								
								<div v-if="!twitterConnected && deliveryby == deliveryType.TWITTERDM"  class="send-details">
									<p>By connecting your account with your Twitter account, you can GIFT a NFT via Twitter's Direct Messaging to your followers.</p>
									<a class="button is-rounded is-outlined has-text-weight-bold" @click="getTwitterUserProfileWithOAuth1()"><i class="fab fa-twitter"></i>Connect</a>
								</div>

								<!-- <div v-if="!discordConnected && deliveryby == deliveryType.DISCORDDM"  class="send-details">
									<p>By connecting your account with your Discord account, you can GIFT a NFT via Discord Direct Messaging to a user.</p>
									<a class="button is-rounded is-outlined has-text-weight-bold" @click="getDiscordUserProfileWithOAuth1()"><i class="fab fa-discord"></i>Connect</a>
								</div> -->

								<ValidationObserver v-slot="{ invalid }">
									<form v-if="availableAssets.length && selectedAsset && (deliveryby == deliveryType.EMAIL || twitterConnected)" @submit.prevent="showConfirmationBox">
										<div class="send-details">
											<div class="field is-horizontal" v-if="deliveryby == deliveryType.TEXT">
												<div class="field-label is-normal">
													<label class="label">To</label>
												</div>
												<div class="field-body">
													<div class="field">
														<ValidationProvider
															name="phone"
															:rules="deliveryby == deliveryType.TEXT ? 'required' : ''"
															v-slot="{ errors, classes }">
															<p class="control">
																<the-mask
																	:mask="['+# (###) ### ####','+### (##) ##### #####' ,'+## (##) #### ######']"
																	:class="classes"
																	class="input"
																	v-model="toPhone"
																	placeholder="Mobile Number"
																/>
															</p>
															<span class="help is-danger">
																{{ errors[0] }}
															</span>
														</ValidationProvider>
													</div>
												</div>
											</div>

											<div class="field is-horizontal" v-if="deliveryby == deliveryType.EMAIL">
												<div class="field-label is-normal">
													<label class="label">To</label>
												</div>
												<div class="field-body">
													<div class="field">
														<ValidationProvider
															name="email"
															:rules="deliveryby == deliveryType.EMAIL ? 'required|email|max:64': ''"
															v-slot="{ errors, classes }">
															<p class="control">
																<input
																	v-model="to"
																	class="input"
																	:class="classes"
																	type="text"
																	placeholder="Recepient email"
																/>
															</p>
															<span class="help is-danger">
																{{ errors[0] }}
															</span>
														</ValidationProvider>
													</div>
												</div>
											</div>

											<div class="field is-horizontal" v-if="deliveryby == deliveryType.TWITTERDM">
												<div class="field-label is-normal">
													<label class="label">To</label>
												</div>
												<div class="field-body">
													<div class="field">
														<ValidationProvider name="to" ref="twitterRef" :rules="deliveryby == deliveryType.TWITTERDM ? 'required': ''" v-slot="{ errors, classes }">
															<p class="control">
																<input
																	v-model="to"
																	class="input"
																	:class="classes"
																	type="text"
																	placeholder="Enter a Twitter follower"
																/>
															</p>
															<span class="help is-danger">
															{{ errors[0] }}
															</span>
														</ValidationProvider>
													</div>
												</div>
											</div>
											
											<!-- <div class="field is-horizontal" v-if="deliveryby == deliveryType.DISCORDDM">
												<div class="field-label is-normal">
													<label class="label">To</label>
												</div>
												<div class="field-body">
													<div class="field">
														<ValidationProvider name="to" ref="discordRef" :rules="deliveryby == deliveryType.DISCORDDM ? 'required': ''" v-slot="{ errors, classes }">
															<p class="control">
																<input
																	v-model="to"
																	class="input"
																	:class="classes"
																	type="text"
																	placeholder="Enter a Discord Username"
																/>
															</p>
															<span class="help is-danger">
															{{ errors[0] }}
															</span>
														</ValidationProvider>
													</div>
												</div>
											</div> -->

											<div class="field is-horizontal">
												<div class="field-label is-normal">
													<label class="label">From</label>
												</div>
												<div class="field-body">
													<div class="field">
														<ValidationProvider
															name="from"
															rules="required|max:64"
															v-slot="{ errors, classes }">
															<p class="control">
																<input
																	v-model="from"
																	class="input"
																	:class="classes"
																	type="text"
																	placeholder="Your name"
                                                                    />
															</p>
															<span class="help is-danger">
																{{ errors[0] }}
															</span>
														</ValidationProvider>
													</div>
												</div>
											</div>

											<div class="field is-horizontal">
												<div class="field-label is-normal">
													<label class="label">Message</label>
												</div>
												<div class="field-body">
													<div class="field">
														<ValidationProvider
															:rules="`max:${messageMaxLength}`"
															v-slot="{ errors, classes }">
															<p class="control">
																<textarea
																	name="message"
																	v-model="message"
																	class="textarea"
																	:class="classes"
																	placeholder="i.e. a gift from me, to you!">
																</textarea>
															</p>
															<span class="help is-danger has-margin-top">
																{{ errors[0] }}
															</span>
														</ValidationProvider>
													</div>
												</div>
											</div>
										</div>
										<div>
											<vue-turnstile site-key="0x4AAAAAAAaIqEaZ8S643Mg6" @verified="verifyCloudflare($event)" />
										</div>
										<div>
										<button
											v-if="availableAssets.length && showSendButton"
											class="send-btn button is-medium is-rounded is-link"
											:disabled="invalid || showLoadingModal"
											@click="showConfirmationBox">
											Send
										</button>
										<a class="is-pulled-right has-text-white twitter-disconnect-link" v-if="twitterConnected && deliveryby == deliveryType.TWITTERDM" @click="updateTwitterConnection(null,true)">Disconnect ({{getScreenName()}})</a>
										</div>
									</form>
								</ValidationObserver>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import axios from "axios";
import UserGiftService from "../core/services/user-gift.service";
import GiftLinkService from "../core/services/gift-link.service";
import UserGiftModel from "../core/models/user-gifts.model";
import GiftModel from "../core/models/gift.model";
import countryCodesJson from "../assets/json/countrycodes.json";
import VueHorizontalList from "vue-horizontal-list";
import { TheMask } from "vue-the-mask";
import CollectionDropdown from '@/components/CollectionDropdown.vue';
import TemplateDropdown from '@/components/TemplateDropdown.vue';
import { ASSETTYPE,DOMAIN, DELIVERYTYPE } from '../core/models/constants'
import VueTurnstile from '@gaviti/vue-turnstile';


// import {
//   ValidationProvider,
// } from "vee-validate";
// import * as rules from "vee-validate/dist/rules";

var AA_API_URL = "https://wax.api.atomicassets.io/atomicassets/v1/";
var SA_API_URL = "https://wax.api.simpleassets.io/v1/"

var pageNumber = 1;
var pageSize = 1000;
// const DeliveryType = Object.freeze({
// 	Email: 1,
// 	Text: 2,
// });
export default {
	components: {
		VueHorizontalList,
		TheMask,
		CollectionDropdown,
		TemplateDropdown,
		VueTurnstile 
	},
	props:{
		accountName: {
			type: String,
			required: true
		},
		giftClient:{
			type: String,
		},
		domain:{
				type: Number,
				required: true
		},
		domainSettings:{
			type: Object
		}
	},
	watch: {
		accountName: function () {
			// watch it
			if (this.accountName) {
				switch(this.domain){
				case DOMAIN.WAX:
					this.allowedTemplates || this.allowedCollection ? this.getCollectionByTemplateIds(this.allowedTemplates,this.allowedCollection) : this.getCollections(pageNumber, pageSize);
                    break;
				case DOMAIN.GYB:
                case DOMAIN.MEME:
                    this.getArkaneCollections();
                    break;
                case DOMAIN.NEWDEX:
					this.getBSCAssets();
                    break;
				case DOMAIN.HEDERA:
					this.getHederaAssets();
                    break;
			}

			}
			else {
				this.availableCollections = [];
				this.availableAssets = [];
				this.availableTemplates = [];
				this.deliveryby = DELIVERYTYPE.Email;
				this.from = "";
				this.to = "";
				this.message =
					"A gift from me, to you! I really hope you enjoy this NFT. Cheers!",
				this.collectionsArray = [];
				this.toPhone = "";
				this.messageMaxLength = "500";
				this.toggleCollectionDropdown = false;
				this.toggleTemplateDropdown = false;
				this.collectionName = "",
				this.templateID = ""
				this.selectedAssetTemplate = "",
				this.toScreenName = ""
				this.removeSelectedAsset()
			}
		},
		giftClient: function(){
			if(this.giftClient){
				this.message = this.giftClient.defaultMessage ? this.giftClient.defaultMessage : this.message
				this.allowedTemplates = this.giftClient.allowedTemplates
				this.allowedCollection = this.giftClient.allowedCollection
				this.allowedTemplates || this.allowedCollection ? this.getCollectionByTemplateIds(this.allowedTemplates,this.allowedCollection) : this.getCollections(pageNumber, pageSize);
			}
		}
	},
    computed:{
        twitterScreenName:function () {
            return localStorage.getItem("twitterScreenName")
        }
    },
	data() {
		return {
			// form fields
			deliveryby: DELIVERYTYPE.EMAIL,
			to: "",
			toPhone: "",
			toScreenName: "",
			phoneCode: "+1",
			from: "",
			message:
			"A gift from me, to you! I really hope you enjoy this NFT. Cheers!",
			collectionName: "",
			templateID: "",
			messageMaxLength: "500",

			// asset preview fields
			selectedAsset: null,
			selectedAssetImgUrl: 'https://giftnft.s3.amazonaws.com/card-placeholder.jpg',
			selectedAssetTemplateMint: "",
			selectedAssetTemplate: "",
            selectedAssetName: "",
            selectedAssetType: null,
            selectedAssetTokenAddress: null,
            selectedAssetTokenID: null,

            // horizontal scroller fields
            scrollStartPos:4,
			sliderOptions: {
				responsive: [
					{ end: 576, size: 3 },
					{ start: 576, end: 768, size: 3 },
					{ start: 768, end: 992, size: 3 },
					{ start: 992, end: 1200, size: 3 },
					{ start: 1200, size: 3 },
				],
				position:{
					start: 0,
				},
				list: {
					padding: 0,
				},
				item: {
					padding: 10,
				}
			},

			// collections dropdown fields
			availableCollections: [],
			toggleCollectionDropdown: false,

			// template dropdown fields
			templateOptionName: "",
			toggleTemplateDropdown: false,
			availableTemplates: [],
			availableAssets: [],

			// UI manipulation
			showConfirmation: false,
			showConfirmModal: false,
			isSendingGift: false,
			showLoadingModal: false,
			transactionID: null,
			waxTxUrl: null,
            errors: [],
			twitterConnected:false,
			//discordConnected:false,

			// data fields
            deliveryType : DELIVERYTYPE,
			collectionsArray: [],
            phoneCodeArray: [],
            componentKey:0,
            allowedCollection:null,
            allowedTemplates:null,
            paramAssetID:null,
            paramAssetTemplateID:null,
            paramAssetTemplateText:null,
            paramAssetCollection:null,
            paramAssetType:ASSETTYPE.AA,
            isFirstLoad:true,
			theme: this.domainSettings.WebsiteName,
			//followers: [],
			showMintNftMsg: false,
			DOMAIN:DOMAIN,
			token: '',
			showSendButton:false
		};
	},
	methods: {
		verifyCloudflare(code){
			let service = new UserGiftService();
			service.verifyCloudflare(code).then((response) => {
				if (response.status == 200) {
					if (response.data.isSuccess) {
						this.showSendButton = true
					}
					else{
						this.errors.push('Failed to verify captcha')
					}
				}
				else{
					this.errors.push('Failed to verify captcha')
				}
			});
		},
        getToName(){
            return this.to ? this.to : this.toPhone
        // if(!this.to){
        //     return ''
        // }
        // if(this.deliveryby == DELIVERYTYPE.TWITTERDM){
        //     const twitterUser = this.followers.find(x => x.id == this.to)
        //     return twitterUser.screenName
        // }
        // else{
        //     return this.to ? this.to : this.toPhone
        // }
        
        },
		async getTwitterUserProfileWithOAuth1() {
			let params = `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=0,height=0,left=-1000,top=-1000`;
			open(`${process.env.VUE_APP_BASE_API_URL}members/twitterauth`, null, params);
		},
		// async getDiscordUserProfileWithOAuth1() {
		// 	let params = `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=0,height=0,left=-1000,top=-1000`;
		// 	open(`${process.env.VUE_APP_BASE_API_URL}members/discordauth`, null, params);
		// },

		async onCollectionChange(assetCount) {
			this.availableAssets = [];
			this.templateID = "";
			this.availableTemplates = [];
			this.removeSelectedAsset();
			this.templateOptionName = "";

			// hide All option for fixed templates
			if(!this.allowedTemplates && !this.allowedCollection){
				this.availableTemplates.push({
					text: `All`,
					value: `All`,
					assetcount: assetCount,
				})
			}

			let filteredCollections = this.collectionsArray.filter(
				(x) => x.collectionName == this.collectionName
			);

			filteredCollections.forEach((e) => {
				if(e.template.templateID){
					let template = this.availableTemplates.find((x) => x.value == e.template.templateID);
					if (!template) {
						this.availableTemplates.push({
							text: `${e.template.templateName} (${e.template.templateID})`,
							value: e.template.templateID,
							assetcount: this.collectionsArray.filter(
								(x) => x.template.templateID == e.template.templateID).length,
						});
					}
				}
			});
           
			// pre select an asset
            //this.toggleDropdown('template')
			if(this.paramAssetCollection && this.isFirstLoad){
				let template = this.availableTemplates.find(x => x.value == this.paramAssetTemplateID)
				if (template) {
					this.setSelectedTemplateOption(template.value,template.text)
				}
				else {
					this.isFirstLoad = false;
				}
			}
			else {
				this.setSelectedTemplateOption(this.availableTemplates[0].value,this.availableTemplates[0].text)
			}

			//this.selectAsset(filteredCollections[0].assets)
		},

		async onTemplateChange() {
			this.availableAssets = [];
			let assets =[]
			if (this.templateID == 'All') {
				assets = this.collectionsArray.filter(x => x.collectionName == this.collectionName)
			}
			else {
				assets = this.collectionsArray.filter(x => x.template.templateID == this.templateID)
			}
			assets.slice(0,419).forEach((a) => {
				this.availableAssets.push(a.assets)
			})
            
			if (this.paramAssetID && this.isFirstLoad) {
				// make this asset first in horizontal list
				this.isFirstLoad = false;
				var asset = this.availableAssets.find(x => x.assetID == this.paramAssetID)
				if (asset) {
					var index = this.availableAssets.indexOf(asset);
					this.availableAssets.splice(index,1)
					this.availableAssets.unshift(asset)
					this.selectAsset(asset)
				}
			}
			else {
				this.selectAsset(this.availableAssets[0])
			}

			this.componentKey += 1;
		},

		async getCollections() {
			if (!this.accountName)	{
				return;
			}
			let service = new UserGiftService();
			service.getAssets(this.accountName).then((response) =>{
				this.collectionsArray = response.data.assets
				this.collectionsArray.forEach((e) => {
					var collection = this.availableCollections.find(
						(x) => x.value == e.collectionName);
						if (!collection) {
							this.availableCollections.push({
								text: e.collectionName,
								value: e.collectionName,
								assetcount: this.collectionsArray.filter(
									(x) => x.collectionName == e.collectionName
									).length,
								});
						}
					});
					if(this.availableCollections.length <= 0){
						this.showMintNftMsg = true;
					}
					// sort alphabetically
					this.availableCollections.sort(function (a, b) {
						if (a.text < b.text) {
							return -1;
						}
						if (a.text > b.text) {
							return 1;
						}
						return 0;
						});

						if(this.paramAssetCollection && this.isFirstLoad){
							let collection = this.availableCollections.find(x => x.value == this.paramAssetCollection)
							if(collection){
							this.setSelectedCollectionOption(collection)
							}
							else{
								this.isFirstLoad = false;
							}
						}
					})
				},
		async getCollectionByTemplateIds(templateIds,collection){ //templateIds : csv = 22364,22896,17476
		if(!templateIds && !collection){
			return;
		}
		if(!this.accountName){
			return;
		}
		let urls = []


		if(collection){
			urls.push(axios.get(`${AA_API_URL}assets?owner=${this.accountName}&collection_name=${collection}&is_transferable=true&page=${pageNumber}&limit=${pageSize}&order=desc&sort=created`))
		}
		else{
		let templates = templateIds.split(",")
		templates.forEach(x =>{
			urls.push(axios.get(`${AA_API_URL}assets?owner=${this.accountName}&template_id=${x}&is_transferable=true&page=${pageNumber}&limit=${pageSize}&order=desc&sort=created`))
		})
		}

		axios
			.all(urls)
			.then(axios.spread((...responses) => {
				let assets = []
				responses.forEach(response => {
					if (response.data["success"]) {
						let data = response.data["data"]

						data.forEach(x =>{
							assets.push(x)
						})
					}
				});

				assets.forEach((e) => {
					this.collectionsArray.push({
					collectionName: e.collection.collection_name,
					schema: e.schema.schema_name,

					template: {
						templateID: e.template ? e.template.template_id : '',
						templateName: (e.template && e.template.immutable_data && e.template.immutable_data.name) ? e.template.immutable_data.name : ''
					},
					assets: {
						imgUrl: `https://wax.atomichub.io/ipfs/${e.data.img}`,
						assetID: e.asset_id,
						name: e["data"]["name"],
						templateMint: e.template_mint,
						templateID: e.template ? e.template.template_id : null,
						type:ASSETTYPE.AA
					},
					});
					// }
				});

					this.collectionsArray.forEach((e) => {
					var collection = this.availableCollections.find(
						(x) => x.value == e.collectionName
					);
					if (!collection) {
						this.availableCollections.push({
						text: e.collectionName,
						value: e.collectionName,
						assetcount: this.collectionsArray.filter(
							(x) => x.collectionName == e.collectionName
						).length,
						});
					}
					});
					if(this.availableCollections.length <= 0){
						this.showMintNftMsg = true;
					}
					// sort alphabetically
					this.availableCollections.sort(function (a, b) {
						if (a.text < b.text) {
							return -1;
						}
						if (a.text > b.text) {
							return 1;
						}
						return 0;
						});
			}));
		},
		async getArkaneCollections() {
			if(!this.accountName){
			return;
			}
			let service = new UserGiftService();
			service.getArkaneAssets(this.accountName).then((response) =>{
				this.collectionsArray = response.data.assets
				this.collectionsArray.forEach((e) => {
					var collection = this.availableCollections.find(
						(x) => x.value == e.collectionName);
						if (!collection) {
							this.availableCollections.push({
								text: e.collectionName,
								value: e.collectionName,
								assetcount: this.collectionsArray.filter(
									(x) => x.collectionName == e.collectionName
									).length,
								});
						}
					});
					if(this.availableCollections.length <= 0){
						this.showMintNftMsg = true;
					}
                   

					// sort alphabetically
					this.availableCollections.sort(function (a, b) {
						if (a.text < b.text) {
							return -1;
						}
						if (a.text > b.text) {
							return 1;
						}
						return 0;
						});

                    if(this.paramAssetID && this.isFirstLoad){
                        var preSelectedAsset = this.collectionsArray.find(x => x.assets.assetID == this.paramAssetID)
                        if(preSelectedAsset){
                            let collection = {value: preSelectedAsset.collectionName}
                            if(collection){
                                this.setSelectedCollectionOption(collection)
                            }
                            else{
                                this.isFirstLoad = false;
                            }
                        }
                    }
					})

                    
		},
        async getBSCAssets() {
			if(!this.accountName){
			return;
			}
			let service = new UserGiftService();
			service.getBSCAssets(this.accountName).then((response) =>{
				this.collectionsArray = response.data.assets
				this.collectionsArray.forEach((e) => {
					var collection = this.availableCollections.find(
						(x) => x.value == e.collectionName);
						if (!collection) {
							this.availableCollections.push({
								text: e.collectionName,
								value: e.collectionName,
								assetcount: this.collectionsArray.filter(
									(x) => x.collectionName == e.collectionName
									).length,
								});
						}
					});
					if(this.availableCollections.length <= 0){
						this.showMintNftMsg = true;
					}
					// sort alphabetically
					this.availableCollections.sort(function (a, b) {
						if (a.text < b.text) {
							return -1;
						}
						if (a.text > b.text) {
							return 1;
						}
						return 0;
						});
					})
		},
			async getHederaAssets(){
			if(!this.accountName){
				return;
			}
			let service = new UserGiftService();
				service.getHederaAssets(this.accountName).then((response) =>{
				this.collectionsArray = response.data.assets
				this.collectionsArray.forEach((e) => {
					var collection = this.availableCollections.find(
						(x) => x.value == e.collectionName);
						if (!collection) {
							this.availableCollections.push({
								text: e.collectionName,
								value: e.collectionName,
								assetcount: this.collectionsArray.filter(
									(x) => x.collectionName == e.collectionName
									).length,
								});
						}
					});
					if(this.availableCollections.length <= 0){
						this.showMintNftMsg = true;
					}
					// sort alphabetically
					this.availableCollections.sort(function (a, b) {
						if (a.text < b.text) {
							return -1;
						}
						if (a.text > b.text) {
							return 1;
						}
						return 0;
						});
					})
		},
		login() {
			this.$parent.childlogin(null);
		},

		logout() {
			this.accountName = "";
			localStorage.removeItem("ual-session-authenticator");
		},

		showConfirmationBox() {
			// show confirm box
			this.showConfirmModal = true;
		},

		async createGifts() {
			this.showConfirmModal = false;
			this.showLoadingModal = true;
			this.errors = [];
			this.validateForm();
			if (this.errors.length > 0) {
				this.showLoadingModal = false;
				return;
			}
			if(!localStorage.getItem("userGuid")){
				this.showLoadingModal = false;
				return;
			}
            if(this.deliveryby == DELIVERYTYPE.TWITTERDM){
                const isValidTwitterName = await this.validateTwitterScreenName()
                if(!isValidTwitterName){
                    this.showLoadingModal = false;
                    return
                }
            }
			let recepients = this.to.split(",");
			//let totalRecepients = recepients.length;
			let gifts = [];

			if (this.selectedAsset) {
			let gift = new GiftModel();

			if (this.deliveryby == DELIVERYTYPE.EMAIL) {
				gift.Email = recepients[0];
			} else if(this.deliveryby == DELIVERYTYPE.TEXT) {
				gift.Phone = `${this.toPhone}`;
			}
            else if(this.deliveryby == DELIVERYTYPE.TWITTERDM) {
				gift.Email = this.to
			}
			gift.AssetID = this.selectedAsset;
			gifts.push(gift);
            setTimeout(() => {
                    this.send(gifts);
              }, 3000);
			}
			//   for multiple recepients, not required for V1
			//   else {
			//     axios
			//       .get(
			//         `${AA_API_URL}assets?owner=${this.waxAccountName}&template_id=${this.templateID}&page=1&limit=${totalRecepients}&order=desc&sort=asset_id`
			//       )
			//       .then((response) => {
			//         if (response.data["success"]) {
			//           let assets = response.data["data"];

			//           // must have assets equal to the number of recepients
			//           if (assets.length < 0 || assets.length < totalRecepients) {
			//             this.errors.push("Not enough assets");
			//             return;
			//           }

			//           assets.forEach((e, i) => {
			//             let gift = new GiftModel();
			//             if (this.deliveryby == DeliveryType.Email) {
			//               gift.Email = recepients[i];
			//             } else {
			//               gift.Phone = recepients[i];
			//             }

			//             gift.AssetID = e["asset_id"];
			//             gifts.push(gift);
			//           });
			//           this.send(gifts);
			//         }
			//       });
			//   }
		},

		async send(gifts) {
			try{
			this.isSendingGift = true;
			let tx = null
			let service = new UserGiftService();
			let keyList = []
			if(this.domain == DOMAIN.WAX){
				//let actions = this.getTransactionActions(gifts);
				// get keys
				let giftLinkService = new GiftLinkService();
				const keyResult = await giftLinkService.generateKeys(
					{
						UserGuid: localStorage.getItem("userGuid"),
					},
					1
				);
				if (keyResult.status == 200) {
					keyList = keyResult.data
					const transferData = {
						assets:gifts,
						assetType:this.selectedAssetType,
						keys : keyList
					}
					tx = await this.$parent.transact(transferData);
				}
				else{
					this.showLoadingModal = false;
					this.isSendingGift = false;
					this.errors.push("Failed to generate keys")
				}
			}
			else if(this.domain == DOMAIN.HEDERA){
				var associateTokenResult = await service.associateToken(this.selectedAssetTokenAddress)
				if(associateTokenResult.data.isSuccess){
					this.showLoadingModal = false;
					const transferData = {
					tokenID:this.selectedAssetTokenAddress,
					serialNo:this.selectedAsset
				}
				tx = await this.$parent.transact(transferData);
				}
				else{
					this.errors.push(associateTokenResult.data.errorMessage)
					this.showLoadingModal = false;
					this.isSendingGift = false;
					return;
				}
			}
			else{
				var transferData = new Object();
				transferData.toAddress = this.domainSettings.HoldingAccount;
				transferData.tokenAddress = this.selectedAssetTokenAddress;
				transferData.tokenID = this.selectedAsset
				tx = await this.$parent.transact(transferData);
			}

			if (tx) {
			let model = new UserGiftModel();
			model.TransactionID = tx;
			model.Gifts = gifts;
			model.FromName = this.from;
			model.Message = this.message;
			model.CollectionName = this.collectionName;
			if(this.domain == DOMAIN.WAX){
				model.TemplateID = this.selectedAssetTemplate ? this.selectedAssetTemplate.toString() : null
			}
			else{
				model.templateID = this.selectedAssetTokenAddress
			}

			model.DeliveryBy = this.deliveryby;
			model.UserGuid = localStorage.getItem("userGuid");
			model.AssetName = this.selectedAssetName
			model.AssetMintNumber = +this.selectedAssetTemplateMint
			model.GiftClientID = this.$route.query.id
			model.AssetImg = this.selectedAssetImgUrl
			model.AssetType = this.selectedAssetType
			model.PublicKey = keyList[0].publicKey
			model.PrivateKey = keyList[0].privateKey
			
			service
				.createUserGift(model)
				.then((response) => {
				this.showLoadingModal = false;
				this.isSendingGift = false;
				if (response.data.isSuccess) {
                    this.showConfirmation = true;
					this.transactionID = response.data.transactionID;
					this.waxTxUrl = `${this.domainSettings.TxExplorerUrl}/${response.data.transactionID}`;
				} else {
                    this.showConfirmation = false;
                    this.errors.push(response.data.errorMessage)
					console.log(response.data.errorMessage);
				}
				})
				.catch((error) => {
				console.log(error);
				this.errors.push(error)
				this.showLoadingModal = false;
				this.isSendingGift = false;
				});
			}
			}
			catch(error){
				this.showLoadingModal = false;
				this.isSendingGift = false;
				this.errors.push(error)
			}
		},

		

		selectAsset(asset) {
			// if (this.selectedAsset == asset.assetID) {
			// 	this.removeSelectedAsset();
			// 	return;
			// }
			this.selectedAsset = asset.assetID;
			this.selectedAssetImgUrl = asset.imgUrl;
			this.selectedAssetTemplateMint = asset.templateMint;
			this.selectedAssetName = asset.name;
			this.selectedAssetTemplate = asset.templateID
			this.selectedAssetType = asset.type
			this.selectedAssetTokenAddress = asset.tokenAddress
			this.selectedAssetTokenID = asset.tokenID
		},

		removeSelectedAsset() {
			this.selectedAsset = null;
			this.selectedAssetImgUrl = 'https://giftnft.s3.amazonaws.com/card-placeholder.jpg';
			this.selectedAssetTemplateMint = "";
			this.selectedAssetName = "";
			this.selectedAssetTemplate = null;
			this.selectedAssetTokenAddress = null
			this.selectedAssetTokenID = null
		},

		validateForm() {
			if (!this.collectionName) {
			//this.errors.push("Please select a collection");
			return;
			}

			if (!this.templateID) {
			//this.errors.push("Please select a template");
			return;
			}

			if (!this.selectedAsset) {
			// this.errors.push("Please select an asset");
			return;
			}

			if (!this.to) {
			// this.errors.push("Please enter a recepient").scrollTop;
			return;
			}

			if (!this.from) {
			//  this.errors.push("Please enter From Name");
			return;
			}

			let recepients = this.to.split(",");
			let emailAddressRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
			let phoneNumberRegex = /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;

			if (this.selectedAsset) {
			if (recepients.length > 1) {
				if (this.deliveryby == DELIVERYTYPE.EMAIL) {
				// this.errors.push("Cannot enter multiple email addresses");
				} else {
				// this.errors.push("Cannot enter multiple phone numbers");
				}
			} else {
				if (this.deliveryby == DELIVERYTYPE.EMAIL) {
				let valid = emailAddressRegex.test(recepients[0]);
				if (!valid) {
					//  this.errors.push("Invalid email " + recepients[0]);
				}
				} else {
				let valid = phoneNumberRegex.test(recepients[0]);
				if (!valid) {
					// this.errors.push("Invalid Phone Number " + recepients[0]);
				}
				}
			}
			} else {
			// validate email ids, and phone numbers
			recepients.forEach((x) => {
				if (this.deliveryby == DELIVERYTYPE.EMAIL) {
				let valid = emailAddressRegex.test(x);
				if (!valid) {
					// this.errors.push("Invalid email " + x);
				}
				} else {
				let valid = phoneNumberRegex.test(x);
				if (!valid) {
					// this.errors.push("Invalid Phone Number " + x);
				}
				}
			});
			}
		},

		changeSendType(type) {
            this.to = "";
			this.deliveryby = type;
			if (this.deliveryby == DELIVERYTYPE.EMAIL) {
			this.messageMaxLength = "500";
			this.toPhone = "";
            this.from = ''
			}
            else if(this.deliveryby == DELIVERYTYPE.TWITTERDM){
                this.messageMaxLength = "500";
                this.toPhone = "";
                this.from = this.getScreenName()                
            }
            else {
			this.messageMaxLength = "150";
			}
		},

		reload() {
			this.$router.go(this.$router.currentRoute);
		},

		toggleDropdown(type) {
			if (type == "collection") {
			this.toggleCollectionDropdown = !this.toggleCollectionDropdown;
			this.toggleTemplateDropdown = false;
			} else if (type == "template") {
			this.toggleTemplateDropdown = !this.toggleTemplateDropdown;
			this.toggleCollectionDropdown = false;
			}
		},

		setSelectedCollectionOption(collection) {
			this.collectionName = collection.value;
			this.toggleCollectionDropdown = false;
			this.onCollectionChange(collection.assetcount);
		},

		setSelectedTemplateOption(value, text) {
			this.templateID = value;
			this.templateOptionName = text;
			this.toggleTemplateDropdown = false;
			this.onTemplateChange();
		},

		closeConfirmModal() {
			this.showConfirmModal = false;
		},

		closeLoadingModal() {
			this.showLoadingModal = false;
			this.isSendingGift = false;
		},
		getAsset(assetID) {
            switch(this.domain){
                case DOMAIN.WAX:
                    if(this.paramAssetType == ASSETTYPE.AA){
                        axios.get(`${AA_API_URL}assets/${assetID}`).then((response) => {
                            if (response.data["success"]) {
                                let data = response.data["data"];
                                this.paramAssetTemplateID = data.template.template_id
                                this.paramAssetTemplateText = `${data.template.immutable_data.name} (${data.template.template_id})`
                                this.paramAssetCollection = data.collection.collection_name
                            }
                        });
                    }
                    else{
                        axios.get(`${SA_API_URL}assets/${assetID}`).then((response) => {
                            if (response.data) {
                                let data = response.data;
                                this.paramAssetTemplateID = 'All'
                                this.paramAssetTemplateText = `All`
                                this.paramAssetCollection = data.author
                            }
                        });
                    }
                    break;
				case DOMAIN.GYB:
                case DOMAIN.MEME:
                    break;
                case DOMAIN.NEWDEX:
                    break;
            }
			

		},
		updateTwitterData(oAuthData){
			this.updateTwitterConnection(oAuthData,false);
		},
		updateTwitterConnection(oAuthData,disconnect){
			axios.post(`${process.env.VUE_APP_BASE_API_URL}members/updatetwitter`,{
				userID: localStorage.getItem("userGuid"),
				twitterAccessToken: disconnect ? null : oAuthData.accessToken,
				twitterAccessSecret: disconnect ? null : oAuthData.accessTokenSecret
				}).then(() =>{
					disconnect ? localStorage.setItem("isTwitterConnected", false) : localStorage.setItem("isTwitterConnected", true)
					this.twitterConnected = disconnect ? false : true
                    
					if(!disconnect){
                        this.from = oAuthData.screenName
                        localStorage.setItem("twitterScreenName", oAuthData.screenName)
					}
                    else{
                        localStorage.removeItem("twitterScreenName");
                    }
				})
		},
		getFollowers(){
			axios.get(`${process.env.VUE_APP_BASE_API_URL}members/twitterfollowers?userID=${encodeURIComponent(localStorage.getItem("userGuid"))}`).then((res) =>{
				if(res.data){
					this.followers = res.data
				}
			})
		},
        getScreenName(){
          return localStorage.getItem("twitterScreenName")  
        },
        validateTwitterScreenName(){
            return axios.get(`${process.env.VUE_APP_BASE_API_URL}members/gettwitteruser?userID=${encodeURIComponent(localStorage.getItem("userGuid"))}&screenName=${this.getScreenName()}&toScreenName=${this.to}`).then((res) =>{
				if(res.data.isSuccess){
					return true
				}
                else{
                    this.errors.push(res.data.errorMessage)
                    return false;
                }
			})
        },
         degreesToRadians(degrees) {
  return degrees * Math.PI / 180;
},

 distanceInKmBetweenEarthCoordinates(lat1, lon1, lat2, lon2) {
  var earthRadiusKm = 6371;

  var dLat = this.degreesToRadians(lat2-lat1);
  var dLon = this.degreesToRadians(lon2-lon1);

  lat1 = this.degreesToRadians(lat1);
  lat2 = this.degreesToRadians(lat2);

  var a = Math.sin(dLat/2) * Math.sin(dLat/2) +
          Math.sin(dLon/2) * Math.sin(dLon/2) * Math.cos(lat1) * Math.cos(lat2); 
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
  return earthRadiusKm * c;
},

getExplorerUrl(){
	switch (this.domain) {
		case DOMAIN.HEDERA:
			return `https://mintbar.xyz/`
			default:
				return this.domainSettings.ExplorerUrl;
	}
}
	},
	mounted() {
		if(window.opener){
            //debugger;// eslint-disable-line no-debugger
            if(this.$route.query.screenname && this.$route.query.accesstoken && this.$route.query.accesstokensecret){
			window.close()
			const twitterOAuthData = {
				"screenName" : this.$route.query.screenname,
				"accessToken" : this.$route.query.accesstoken,
				"accessTokenSecret" : this.$route.query.accesstokensecret
			}
			window.opener.twitterOAuthCallback(twitterOAuthData)
            }
		}
		countryCodesJson.forEach((x) => {
			this.phoneCodeArray.push({
			text: `${x.code} (${x.dial_code})`,
			value: x.dial_code,
			});
		});
		if(this.giftClient){
			this.message = this.giftClient.defaultMessage ? this.giftClient.defaultMessage : this.message
			this.allowedTemplates = this.giftClient.allowedTemplates
			this.allowedCollection = this.giftClient.allowedCollection
		}

		if (this.accountName) {
            switch(this.domain){
				case DOMAIN.WAX:
					this.allowedTemplates || this.allowedCollection ? this.getCollectionByTemplateIds(this.allowedTemplates,this.allowedCollection) : this.getCollections(pageNumber, pageSize);
                    break;
				case DOMAIN.GYB:
                case DOMAIN.MEME:
                    this.getArkaneCollections();
                    break;
                case DOMAIN.NEWDEX:
					this.getBSCAssets();
                    break;
				case DOMAIN.HEDERA:
					this.getHederaAssets();
                    break;
			} 
		}

		// if route has assetID pre-populate
		if(this.$route.params.assetID){
			this.paramAssetID = this.$route.params.assetID
			this.paramAssetType = this.$route.params.assetType == 'aa' ? ASSETTYPE.AA : ASSETTYPE.SA
			this.getAsset(this.paramAssetID)
		}
        //check if user has twitter authenticated already
		const twitterConnectedToken = localStorage.getItem('isTwitterConnected')
        if(twitterConnectedToken){
			if(twitterConnectedToken === "true"){
				this.twitterConnected = true
				//this.getFollowers();
			}			 
        }
	},
};
</script>

<style scoped lang="scss">
	$padding: 60px;

	.gift-nft {
		position: relative;
		z-index: 30;

		@media screen and (min-width: $desktop) {
			margin-top: $padding;
			margin-bottom: $padding * 2;
		}

		@media screen and (max-width: $desktop) {
			padding: 40px 0;
		}

		@media screen and (max-width: 766px) {
			overflow-x: hidden;
			overflow-y: visible;
			padding: 40px 30px;
		}
	}

	.confirm-send.modal {
		.button.is-text {
			color: $danger;

			&:hover {
				background: inherit;
			}
		}
	}

	.confirm-loading {

		.modal-card {
			width: 340px;
			border-radius: 5px;

			h1 {
				margin-top: 10px;
				font-size: 18px;
				color: $text;
				font-weight: bold;
			}

			p {
				margin-top: 10px;
				color: $text;
			}

			.modal-card-body {
				padding: 40px 50px;
			}
		}
	}

	.columns.is-full-height {
		min-height: calc(100vh - #{$navbar-height} - #{$footer-height} - #{$padding});

		@media screen and (max-width: 766px) {
			min-height: calc(100vh - #{$navbar-height} - 224px - 80px);
		}
	}

	.login-required {
		position: relative;

		h1,
		p {
			margin-bottom: 30px !important;
			color: $white;
		}

		p {
			margin-bottom: 35px;
		}

		// UAL button styling in global.scss
	}

	.gift-preview {
		position: relative;
		margin-right: 30px;
		overflow: hidden;
		background: $white;
		border-radius: 5px;
		box-shadow: $box-shadow-large;

		// @media screen and (min-width: $tablet) and (max-width: $desktop) {
		//     margin-left: 30px;
		//     margin-right: 30px;
		// }

		.header {
			$size: 30px;
			padding: 10px 20px;
			border-bottom: 1px solid $border;

			img {
				display: inline-block;
				vertical-align: middle;
				width: auto;
				height: $size;
				margin-top: 1px;
			}

			.button {
				float: right;
				height: $size;
				border-radius: 1000px;
			}
		}

		.asset-preview {
			padding: 30px 50px;
			text-align: center;
			background: $light;
			overflow: hidden;

			img {
				display: inline-block;
				vertical-align: middle;
				-webkit-filter: drop-shadow(0 2px 3px rgba(black, 0.3));
				filter: drop-shadow(0 2px 3px rgba(black, 0.3));
				// box-shadow: 0 2px 3px rgba(black, 0.3);
			}

			.asset-name {
				margin-top: 15px;
				background: light;
				line-height: 1;

				.num,
				.name,
				.column {
					display: block;
					font-weight: bold;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
				}

				.num {
					padding-right: 10px;
					color: $text;
					text-align: left;
				}

				.name {
					padding-left: 10px;
					color: $primary;
					text-align: right;
				}
                .name-center {
					padding-left: 10px;
					color: $primary;
					text-align: center;
				}
			}
		}

		.msg-box {
			padding: 15px 20px;
			border-top: 1px solid $border;

			p {
				margin: 0;
				color: $info;
				// font-weight: bold;
				// text-align: center;
			}
		}
	}

	.preview-disclaimer {
		margin: 15px 30px 0 0;
		font-size: 10px;
		text-transform: uppercase;
		color: rgba($white, 0.6);
		text-align: center;
		font-weight: bold;
		line-height: 1.2;
	}

	.gift-form,
	.gift-form-confirmation {
		// @media screen and (min-width: $tablet) and (max-width: $desktop) {
		//     margin-right: 30px;
		// }

		h2 {
			margin-bottom: 25px;
			color: $link;
		}

		p {
			margin-bottom: 30px !important;
			color: $white;

			strong {
				color: $white;
			}
		}

		.label {
			margin-bottom: 15px;
			color: $white;
			line-height: 1;
		}

		input,
		select,
		textarea {
			border: none;
			box-shadow: none !important;
		}

		.select {
			&:after {
				border-color: $primary !important;
			}

			&:hover:after {
				border-color: $link;
			}
		}

		.send-method {
			margin-bottom: 30px;

			.button {
				width: 100px;
				font-weight: bold;
				border: none !important;
				color: $text;

				&:hover:not(.is-selected) {
					border-color: inherit;
					color: $text;
				}

				&.is-selected {
					background: $primary;
					color: $white;
					border-color: $primary;
				}
			}
		}

		.send-details {
			position: relative;
			margin-bottom: 30px;
			padding: 20px;
			border: 2px solid $primary;
			border-radius: 5px;
			background: rgba($dark, 0.6);

			label.label {
				margin-bottom: 0;
				line-height: inherit;
			}

			> .field:last-child p.control {
				margin-bottom: 0 !important;
			}

			.field p.control {
				margin-bottom: 10px !important;
			}

            .field div.control {
				margin-bottom: 10px !important;
			}

			.field.has-addons .control:not(:last-child) {
				border-right: 2px solid $border;
			}

			input.invalid {
				border: solid 1px #ff654d;
			}

			textarea.invalid {
				border: solid 1px #ff654d;
			}

			.help {
				margin-top: 0;

				&.has-margin-top {
					margin-top: 10px;
				}
			}
		}

		button.send-btn,
		button.create-new {
			display: inline-block;
			margin-bottom: 80px;
			padding: 0px 30px;
			height: 46px;
			text-transform: uppercase;
			background: $gradient;
			background-repeat: no-repeat;
			font-weight: bold;
			font-size: $body-size;
			border: none;
			transition: all 0.2s linear;

			&:hover {
				border: none;
				background: $gradient;
				transform: translateY(1px);
			}

			&[disabled] {
				opacity: 1;
				// background: $border;
			}
		}

		.nft-selector {
			margin-top: 20px;

			@media screen and (max-width: 766px) {
				max-width: calc(100vw - 60px);
			}

			.vue-horizontal-list {
				margin: -24px 0 26px 0 !important;

				.item {
					position: relative;
					display: block;
					padding: 15px;
					border: 2px solid darken($dark, 5%);
					border-radius: 5px;
					background: rgba($dark, 0.6);
					cursor: pointer;
					overflow: hidden;

					.img-container {
						position: relative;
						width: 100%;
						height: 150px;
						overflow: hidden;
						box-sizing: border-box;
					}

					&.is-selected {
						border-color: $primary;

						.item-name {
							border-color: $primary;
							background: $primary;
							color: $white;
						}
					}

					img {
						position: absolute;
						top: 50%;
						left: 50%;
						transform: translate(-50%, -50%);
						height: auto;
						max-height: 100%;
						max-width: 100%;
					}

					.item-name {
						margin-top: 15px;
						border: 1px solid darken($dark, 10%);
						background: darken($dark, 3%);
						// border: 1px solid $link;
						padding: 8px 10px;
						text-align: center;
						border-radius: 1000px;
						color: $white;
						text-transform: uppercase;
						font-size: 10px;
						font-weight: bold;
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
					}

					.item-num {
						position: absolute;
						display: inline-block;
						top: 5px;
						right: 5px;
						// background: darken($dark, 5%);
						border: 1px solid darken($dark, 10%);
						background: darken($dark, 3%);
						padding: 6px 10px;
						text-align: center;
						border-radius: 1000px;
						color: $white;
						text-transform: uppercase;
						font-size: 12px;
						font-weight: bold;
						// box-shadow: $box-shadow;
					}
				}
			}
		}
	}

	.message-header {
		p {
			margin-bottom: 0px !important;
		}
    }
	.twitter-disconnect-link{
		font-size: 12px;
		vertical-align: middle;
		line-height: 36px;
	}
</style>