<template>
	<div class="navbar-item has-dropdown" :class="{ 'is-active': isDropdownActive }" v-on-clickaway="away">
		<a
			@click="isDropdownActive = !isDropdownActive"
			class="navbar-link button is-link is-rounded is-outlined is-uppercase has-text-weight-bold"
			v-text="getDsiplayName()">
		</a>

		<div class="navbar-dropdown is-boxed is-right">
			<a
				@click="setDropdownState"
				class="navbar-item"
				target="_new"
				:href="getExplorerUrl()">
				My Collections
			</a>

			<router-link
				v-if="showMyGifts"
				@click.native="setDropdownState"
				class="navbar-item"
				:to="{path:'/mygifts', query: {id: $route.query.id}}">
				My Gifts
			</router-link>

			<router-link
				@click.native="setDropdownState"
				class="navbar-item"
				:to="{path:'/myclaims', query: {id: $route.query.id}}">
				My Claims
			</router-link>

            <router-link
				@click.native="setDropdownState"
				class="navbar-item"
				:to="{path:'/links',props:true, query: {id: $route.query.id}}" v-if="isAdmin"
                :isAdmin="isAdmin">
				Gift Links
			</router-link>

			<a
				class="navbar-item"
				target="_new"
				v-if="domain == DOMAIN.HEDERA"
				href="https://mintbar.xyz/">
				Mint NFTs
			</a>

			<!-- <hr class="navbar-divider"> -->

			<a @click="logout" class="navbar-item">Logout</a>
		</div>
	</div>
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway';
import { DOMAIN } from '../core/models/constants'

export default {
	props: {
		accountName: {
            type: String,
            required: true
		},
		showMyGifts: {
            type: Boolean,
            required: true
		},
		domain:{
             type: Number,
             required: true
        },
        domainSettings:{
            type: Object
        },
        isAdmin: {
            type: Boolean,
            required: true
        }
	},
	mixins: [ clickaway ],
	data() {
		return {
			DOMAIN:DOMAIN,
            isDropdownActive: false,
            atomichubUrl:process.env.VUE_APP_ATOMICHUB_URL
		}
	},
	methods: {
		away() {
			this.isDropdownActive = false;
		},
		logout() {
			this.$emit('logout');
			this.isDropdownActive = false;
        },
        setDropdownState(){
            this.isDropdownActive = false
            this.$emit('toggleMobileMenu');
		},
		getDsiplayName(){
			switch(this.domain){
				case DOMAIN.WAX:
				case DOMAIN.HEDERA:
					return this.accountName
				case DOMAIN.GYB:
                case DOMAIN.MEME:
                case DOMAIN.NEWDEX:
					return `${this.$options.filters.truncate(this.accountName,11,'...')}${this.accountName.substring(this.accountName.length - 4,this.accountName)}`
			}
        },
        getExplorerUrl(){
            switch(this.domain){
				case DOMAIN.WAX:
                    return `${this.domainSettings.ExplorerUrl}/profile/${this.accountName}#inventory`
				case DOMAIN.GYB:
                case DOMAIN.MEME:
                case DOMAIN.NEWDEX:
					return `${this.domainSettings.ExplorerUrl}/account`
				case DOMAIN.HEDERA:
					return `${this.domainSettings.ExplorerUrl}`
			} 
		},
	}
};
</script>

<style scoped lang="scss">
</style>