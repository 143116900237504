<template>
	<div class="home">
		<div class="container is-max-desktop">
			<div class="columns is-centered is-gapless is-desktop">
				<div class="column is-11-tablet is-11-desktop">
					<div class="columns is-full-height is-vcentered is-gapless is-mobile is-multiline">
						<div class="column is-full-mobile is-half-tablet is-5-desktop">
							<div class="card-container">
								<div
									v-for="(img, index) in homeCardImgUrls"
									v-bind:key="img"
									class="card"
									:class="[`card-${index + 1}`]"
								>
									<img :src="img" />
								</div>
							</div>
						</div>
						<div class="column is-full-mobile is-half-tablet is-7-desktop">
							<div class="text-box content">
								<div v-if="partnerLogo" class="partner-logo">
									<img :src="partnerLogo" />
								</div>
								<div class="free-arrow">
									<img src="@/assets/img/free-arrow.svg" alt="" />
								</div>
								<h1>
									Securely gift your NFTs to anyone with just a few clicks.
								</h1>
								<p>
									{{ homeDescription }}
								</p>
								<button
									@click="gotoGifts"
									class="button is-medium is-rounded is-link"
									v-if="isValidClient"
								>
									Start Here
								</button>

								<button class="coming-soon button is-medium is-rounded is-link" v-if="!isValidClient">
									Coming Soon!
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
//import UserGiftService from "../core/services/user-gift.service";
import { DOMAIN } from '../core/models/constants'

export default {
	props:{
        accountName: {
			type: String,
			required: true
        },
        giftClient:{
            type: String,
        },
        domain:{
             type: Number,
             required: true
        },
        domainSettings:{
			type: Object,
			required: true
		},
		isValidClient:{
			type: Boolean,
			required: true
		}
    },
	watch: {
		giftClient: function () {
			this.homeDescription = this.giftClient.homeDescription;
			this.homeCardImgUrls = this.giftClient.homeCardImgUrls
			this.partnerLogo = this.giftClient.logoImgUrl
		}
	},
	data() {
		return {
			partnerLogo: null,
			homeDescription:
				`Artists can now gift their work to friends and family via Email or SMS. NFTs are transferred securely using the ${this.domain == DOMAIN.WAX ? 'WAX' : 'ETHEREUM'} blockchain technology.`,
			homeCardImgUrls: [],
			theme: this.domainSettings.WebsiteName
			//isValidClient: false
		};
	},
	methods: {
		gotoGifts() {
			if (this.accountName) {
				this.$router.push(
				{
				path: `/gift`,
				query: this.$route.query,
				})
			} else {
				this.$parent.childlogin(`/gift`);
				//this.$router.push("gift");
			}
		},
        getHomeDescription(){
            switch (this.domain) {
				case DOMAIN.GYB:
                    this.homeDescription = `Artists can now gift their work to friends and family via Email. NFTs are transferred securely using the ETHEREUM blockchain technology.`
					break;
                case DOMAIN.MEME:
                    this.homeDescription = `Gift a Marble card NFT from Meme.com via Email or Twitter DMs. NFTs are transferred securely using the POLYGON network.`
					break;
                case DOMAIN.NEWDEX:
                    this.homeDescription = `Artists can now gift their work to friends and family via Email or Twitter DMs. NFTs are transferred securely using the BSC blockchain technology.`
					break;
				case DOMAIN.HEDERA:
                    this.homeDescription = `Artists can now gift their work to friends and family via Email or Twitter DMs. NFTs are transferred securely using the HEDERA blockchain technology.`
					break;
				default:
                    this.homeDescription = `Artists can now gift their work to friends and family via Email or Twitter DMs. NFTs are transferred securely using the WAX blockchain technology.`
					break;
			}
        }
	},
	mounted() {
		// if no client id, load default images
        this.getHomeDescription()
		//console.log('domain',this.domainSettings)
        //console.log('theme',this.theme)
        if(!this.$route.query.id) {
			switch (this.domain) {
				case DOMAIN.GYB:
					this.homeCardImgUrls = [
						'https://i.ibb.co/zFTGgQL/SNLBY.gif',
						'https://lh3.googleusercontent.com/sjsd6yyxiXNRZzK7XVaD-hKoDxuOSFlbZqY88bboZQrN0_fsrLDPCrihy715KsmOmOXgAGa2KoF_uD104PvASQ5e',
						'https://lh3.googleusercontent.com/7otHHI76N_gKex7iZLhjQtwYZlUNxW9njE7ijREucSmp-ysjk17w2AylSQPm4qluUS34l4jMhDrMc6ENMp4I_H4U'
					]
					break;
                case DOMAIN.MEME:
                case DOMAIN.NEWDEX:
					this.homeCardImgUrls = [
						`${this.domainSettings.ImageExplorerUrl}/QmRjoT1kgNba3L8tyYmEi4qKZ9mPUAYBR59CuJLibT1AL3`,
						`${this.domainSettings.ImageExplorerUrl}/Qmbquoqf7nUrnT2vGjkymkUXF78FcE5HPk3Vo7zi64oka5`,
						`${this.domainSettings.ImageExplorerUrl}/QmSibgtnW3AwzG7rurKwEn7GVYTSwspF2pENFKa5DdYoiZ`
					]
					break;
				case DOMAIN.HEDERA:
					this.homeCardImgUrls = [
						'https://cloudflare-ipfs.com/ipfs/QmaHVnnp7qAmGADa3tQfWVNxxZDRmTL5r6jKrAo16mSd5y/4432.png',
						'https://cloudflare-ipfs.com/ipfs/QmUiwiG8zkpvc7oRY4Twj9hzkz8hPCQEMcSAdKr48V18zi',
						'https://cloudflare-ipfs.com/ipfs/bafybeiaru5ekbza4hky4h7ioqizzbda6y7ugq4tt4v4xn74xem5tsikvoy/genart_14.jpg'
					]
					break;
				default:
					this.homeCardImgUrls = [
						`${this.domainSettings.ImageExplorerUrl}/QmcAkyEvUNgc6CDKn9yQP9my6pCz5Dk21amr2t6pdZocDZ/gum/54a.gif`,
						`${this.domainSettings.ImageExplorerUrl}/QmTmMQXVy6T6uXUPsUkttk3QAJBGQmxAgwQQbv6gm6CzJu/14.gif`,
						`${this.domainSettings.ImageExplorerUrl}/QmYvSdTwAWdokpZFEMfMfKq23NT8GhUHe96M3nV79NaZtt/laminated/Atari_Box_Laminated_61.gif`
					]
					break;
			}
        }
	},
};
</script>

<style scoped lang="scss">

	.home {
		position: relative;
		z-index: 10;

		@media screen and (max-width: $desktop) {
			padding: 40px 0;
		}

		@media screen and (max-width: 766px) {
			overflow-x: hidden;
			overflow-y: visible;
		}
	}

	@media screen and (min-width: $tablet) {
		.columns.is-full-height {
			min-height: calc(100vh - #{$navbar-height} - #{$footer-height});
		}
	}

	.card-container {
		position: relative;
		width: 300px;
		height: 500px;
		//background: rgba(white, 0.05);

		@media screen and (max-width: 766px) {
			width: 100%;
			height: 500px;
		}

		.card {
			position: absolute;
			display: block;
			box-shadow: $box-shadow;
			overflow: hidden;
			border-radius: 0;
			transform-style: preserve-3d;

			img {
				display: block;
				border: 5px solid $white;
				width: 100%;
				height: auto;
				max-width: 180px;

				@media screen and (max-width: 766px) {
					max-width: 200px;
				}
			}

			&.card-1 {
				top: 0;
				right: 0;
				transform: rotate(5deg) translatez(0); // translatez(0) fix for jagged edges on rotate
				outline: 1px solid transparent; // fix for jagged edges on rotate
				z-index: 3;

				@media screen and (max-width: 766px) {
					right: 30px;
				}
			}

			&.card-2 {
				top: 50%;
				left: 0;
				transform: translateY(-50%) rotate(-15deg) translatez(0); // translatez(0) fix for jagged edges on rotate
				outline: 1px solid transparent; // fix for jagged edges on rotate
				z-index: 2;
			}

			&.card-3 {
				bottom: 0;
				right: 0;
				transform: rotate(15deg) translatez(0); // translatez(0) fix for jagged edges on rotate
				outline: 1px solid transparent; // fix for jagged edges on rotate
				z-index: 1;
			}
		}
	}

	.text-box {
		position: relative;
		margin-left: 100px;

		@media screen and (max-width: 766px) {
			margin: 50px 60px 30px;
		}

		@media screen and (min-width: $tablet) and (max-width: $desktop) {
			margin-left: 80px;
		}

		.partner-logo {
			margin-bottom: 30px;

			img {
				max-height: 68px;
			}
		}

		h1 {
			position: relative;
			font-size: 32px;
			margin: 0 0 30px !important;
			color: $white;
		}

		p {
			margin-bottom: 40px !important;
			color: $white;
		}

		button {
			display: inline-block;
			padding: 0px 30px;
			height: 46px;
			text-transform: uppercase;
			background: $gradient;
			background-repeat: no-repeat;
			font-weight: bold;
			font-size: $body-size;
			border: none;
			transition: all 0.2s linear;

			&:hover {
				border: none;
				background: $gradient;
				transform: translateY(1px);
			}

			&.coming-soon {
				border-radius: 5px;
				color: $link;
				background: transparent;
				border: 1px dashed $link;
				cursor: default;
			}
		}

		.free-arrow {
			position: absolute;
			left: -110px;
			top: -30px;

			@media screen and (max-width: 766px) {
				display: none;
				// left: 0;
				// top: -90px;
			}

			img {
				max-width: 90px;
			}
		}
	}
</style>